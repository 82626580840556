import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYelp, FaGoogle, FaTripadvisor, FaAngellist, FaThumbtack } from 'react-icons/fa';
import { SiTrustpilot } from 'react-icons/si';
import { AiFillHome } from 'react-icons/ai'; // Example for Better Business Bureau, as it doesn't have a specific icon
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { DefaultButton, IIconProps } from '@fluentui/react';
import {
  Button
} from "@fluentui/react-components";





const socialLinks = [
  { name: 'Facebook', url: 'https://www.facebook.com/ComputersNCellPhones/', icon: <FaFacebookF /> },
  { name: 'Twitter', url: 'https://twitter.com', icon: <FaTwitter /> },
  { name: 'Instagram', url: 'https://instagram.com', icon: <FaInstagram /> },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/company/tampa-bay-computer-repair', icon: <FaLinkedinIn /> },
  { name: 'Yelp', url: 'https://www.yelp.com/biz/tampa-bay-computer-repair-tampa-2', icon: <FaYelp /> },
  { name: 'Google Maps', url: 'https://g.page/r/CVvxbMpp925bEBM', icon: <FaGoogle /> },
  { name: 'Trustpilot', url: 'https://www.trustpilot.com/review/tampabaycomputerrepairllc.com', icon: <SiTrustpilot /> },
  { name: 'Better Business Bureau', url: 'https://www.bbb.org', icon: <AiFillHome /> },
  { name: 'TripAdvisor', url: 'https://www.thumbtack.com/fl/tampa/computer-repair/tampa-bay-computer-repair/service/510531957032902667?utm_medium=web&utm_source=txt&surface=sp&referrer_pk=510531917784817669', icon: <FaThumbtack /> },
  { name: 'Angie\'s List', url: 'https://cctxvcamerasolution.sitesgpt.com/computers-repair-tampa-bay', icon: <FaAngellist /> }
];

const LinkBio = () => {
  // Handler to open links
  const handleLinkClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="min-h-screen bg-blue-500">
      
      <main className="p-4 flex flex-wrap justify-center items-center gap-4">
        {socialLinks.map((link, index) => (
          <Button style={{color: "white", backgroundColor: "#008080", borderColor: "black"}}
            key={index}
            name={link.name}
            icon={link.icon}
            onClick={() => handleLinkClick(link.url)}
           
            
          />
        ))}
      </main>
      
    </div>
  );
};


export default LinkBio;

