import emailjs from "emailjs-com";
import {
  Input,
  Field,
  DialogContent,
  Dialog,
  DialogActions,
  DialogSurface,
  DialogBody,
  Button,
  DialogTrigger,
  Textarea,
  DialogTitle,
} from "@fluentui/react-components";
import StarRating from "./StarRating";
function Review() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const btn = document.getElementById("button") as HTMLInputElement;
    const form = document.getElementById("form") as HTMLFormElement;
    
    

    if (btn && form) {
      btn.value = "Sending...";
      const userID = "s7Z62Y28RdnaBNazK";
      const serviceID = "default_service";
      const templateID = "template_hnhp4ys";

      emailjs.sendForm(serviceID, templateID, form, userID).then(
        () => {
          btn.value = "Send Email";
          alert("Review Submitted!");
        },
        (err) => {
          btn.value = "Send Email";
          alert(JSON.stringify(err));
        }
      );
    }
  };

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger disableButtonEnhancement>
        <Button style={{color: "white", backgroundColor: "#2f5cc4", borderColor: "black"}}>Submit a Review</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Let us know how we are doing.</DialogTitle>
          <DialogContent>
            <form id="form" onSubmit={handleSubmit}>
                <StarRating></StarRating>

              <div className="field">
                <Field label="Name">
                  <Input type="text" name="name" id="name"></Input>
                </Field>
              </div>
              <div className="field">
                <Field label={"Email"}>
                  <Input type="text" name="email" id="email"></Input>
                </Field>
              </div>
              
              <div className="field">
                <Field label="Review Comment*">
                  <Textarea required name="issue" id="issue"></Textarea>
                </Field>
              </div>
              
              <DialogActions>
                <Button
                  appearance="primary"
                  style={{ marginTop: "10px" }}
                  type="submit"
                  value="Send Email"
                  id="button"
                >
                  Submit Review
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default Review;