import React from 'react';
import {Button} from "@fluentui/react-components";

const CallButton = () => {
  const phoneNumber = '813-506-3416';

  const handleButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
  
  <Button style={{color: "white", backgroundColor: "#2f5cc4", borderColor: "black"}} onClick={handleButtonClick}>
  📞Call Us {phoneNumber}</Button>
  
    
  );
};

export default CallButton;
