import React, { useState, useEffect, useRef } from 'react';
import logo from "./images/logo.png";
import microsoft from "./images/microsoft.png";
import technician from "./images/technician.png";
import serviceRadius from "./images/serviceradius.jpg";
import Appointment from "./Appointment";
import Review from "./Review";
import CallButton from "./CallButton";
import "./App.css";
import {
  FluentProvider,
  webLightTheme,
  Title1,
  Button,
} from "@fluentui/react-components";
import LinkBio from './LinkBio';

function App() {
  const [isFloating, setIsFloating] = useState(false);
  const elementRef1 = useRef<HTMLDivElement>(null);
  const elementRef2 = useRef<HTMLDivElement>(null);
  const elementRef3 = useRef<HTMLDivElement>(null);
  
  const handleClick1 = () => {
    if (elementRef1.current != null) {
      elementRef1.current.scrollIntoView();
    }
  };
  const handleClick2 = () => {
    if (elementRef2.current != null) {
      elementRef2.current.scrollIntoView();
    }
  };
  const handleClick3 = () => {
    if (elementRef3.current != null) {
      elementRef3.current.scrollIntoView();
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position to determine whether the header should float
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsFloating(true);
      } else {
        setIsFloating(false);
      }
    };

    // Attach the event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
    // State to manage visibility
    const [isVisible, setIsVisible] = useState(true);
  
    // Function to toggle visibility
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
  
  return (
    <FluentProvider theme={webLightTheme}>
      <div>
        <body>
        <div className={`nav ${isFloating ? 'floating' : ''}`} >
            <div className='floatcontainer'>
              <div className='pb'>
                
                <h1><u>Tampa Bay Computer Repair</u></h1>
              </div>
             <div className='item2'>
             <div className="navcontainer">
              <Button style={{ maxWidth: "180px" }} onClick={handleClick1}>
                About Us
              </Button>
              <Button style={{ maxWidth: "180px" }} onClick={handleClick2}>
                Our Services
              </Button>
              <Button style={{ maxWidth: "180px" }} onClick={handleClick3}>
                Contact Us
              </Button>
              </div>
              </div>
             <div style={{paddingBottom: "1px",paddingRight: "1px" }}>
              <CallButton></CallButton>
              
              <Appointment></Appointment>
             </div>
           </div>
          </div>
          <header>
            <div>
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top' }}>
              <LinkBio></LinkBio>
              </div>
              
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                

                <img src={logo} alt="logo" width="175" height="175"></img>
              
                <a href='https://www.bbb.org/us/fl/tampa/profile/computer-repair/tampa-bay-computer-repair-llc-0653-90443234/#sealclick' target="_blank" rel="noreferrer"><img src='https://seal-westflorida.bbb.org/seals/blue-seal-153-100-whitetxt-bbb-90443234.png' alt='Tampa Bay Computer Repair LLC BBB Business Review' /></a>
                
                </div>
              
              
              
              <br></br>

              <Title1>
                Mobile Computer Repair in the Tampa Bay Area
              </Title1>

              <p>Professional, Knowledgeable, and Affordable</p>
              <p>Servicing <b>Businesses and Business Clients</b> in their homes</p>
              <Button style={{ maxWidth: "400px" }} onClick={handleClick1}>
                4.9 / 5 stars from 39 reviews ⭐️⭐️⭐️⭐️⭐️
              </Button>

              <p><b>Mon-Sat 8:00am - 8:00pm</b></p>
              
              
            </div>
          </header>
          
          <div className="container">

            
            <div style={{ position: 'relative', border: '1px solid black', marginRight: '0px' }} className="item1" >
              <section ref={elementRef1} id="about">
              <Button style={{ 
                maxWidth: "25px",
        position: 'absolute', 
        top: 0, 
        right: 0,
        marginTop: '15px', /* Adjust as needed */
        marginRight: '0px', /* Adjust as needed */
      }} onClick={toggleVisibility}>
        {isVisible ? 'Hide' : 'Show'} 
      </Button>
      {isVisible && (
        <div>
              <h2><u>Recent Reviews</u></h2>
    <p>
      ⭐️⭐️⭐️⭐️⭐️ <b>5/5 stars </b>
      <br></br>
      Jon was great, fixed our printer problem
      real quick. He was very professional and respectful. I will be
      sure to call them again if needed and recommend them to people
      in need of computer repair. Thanks Jon.
      <br></br>
      <b>- Ramon J.</b>
    </p>
    <p>
      ⭐️⭐️⭐️⭐️⭐️ <b>5/5 stars </b>
      <br></br> This is the 2nd time I've had a tech come
      out from this company, and both times they were great.
      Super-friendly, diagnosed the issues, fixed everything I
      needed in no time flat and for a very, very fair price as
      well. I HIGHLY recommend this company, for anyone who needs a
      tech to come and work on their PC issues. Five stars!! 
      <br></br>
      <b>- Saray R.</b>
    </p>
    <p>
      ⭐️⭐️⭐️⭐️⭐️ <b>5/5 stars </b>
      <br></br> Jeff was a huge help- very knowledgeable and
      prepared for all the issues with my PC. Service was fast and
      efficient. I was really worried about the whole process
      beforehand and Jeff made it an absolute breeze. 
      <br></br>
      <b>- Duchenne B.</b>
    </p>
    <p>
      ⭐️⭐️⭐️⭐️⭐️ <b>5/5 stars </b>
      <br></br> Richard was very helpful today and was able
      to explain everything so we could understand. Very nice and
      patient with us and had us up and running quickly. Thank you!
      <br></br>
      <b>- Karen C.</b>
    </p>
    <p>
      ⭐️⭐️⭐️⭐️⭐️ <b>5/5 stars </b>
      <br></br> Jon is an awesome technician/computer repair
      technician. He really knows his stuff. He always shows up on
      time, is reasonably priced and always gets the job done.
      Strongly recommend him for all your computer/network problems
      <br></br>
      <b>- Debrah S.</b>
    </p>
    
    <Review></Review>
    </div>)}
              </section>
            </div>
            <div className="item2">
              <section ref={elementRef1} id="about us">
                <h2>We come to you</h2>

                <p>
                  The phrase "mobile computer repair" means that we visit your  
                  <b> Office</b> location, to solve any computer-related issues you may have.
                </p>
                <div className="technician">
                  <img
                    style={{ marginTop: "20px" }}
                    className="technician"
                    src={technician}
                    alt="Trustworthy Technician"
                    width="275"
                    height="275"
                  ></img>
                </div>
                <h3>Who is Tampa Bay Computer Repair?</h3>
                <p className="p">
                  We are your Expert Computer Repair Engineers that provides
                  fast and affordable solutions to all your computer problems.
                  Our team of experts is knowledgeable and experienced with a
                  commitment to excellence, we prioritize the security and
                  efficiency of your computing environment. Count on us for
                  expert service, transparent communication, and a commitment to
                  your satisfaction, making us your reliable choice for all
                  things computer repair.
                </p>
                <img
                src={microsoft}
                alt="microsoft certified"
                
                ></img>
                <p>{" "}<CallButton></CallButton></p>
                <p>
                  {" "}
                  <Appointment></Appointment>
                </p>
              </section>

              <section ref={elementRef2} id="services">
                <h2>Introducing Tampa Bay's Premier Computer Experts!</h2>
                <p className="p">
                  
                  We pride ourselves on providing reliable service to <b>businesses
                  and business clients in their home</b> in the Greater Tampa Bay. 
                </p>
                <p>
                  We understand the importance of having a fully functional
                  computer, and we're here to help.
                </p>

                <p>
                  🌐 Explore cutting-edge IT Solutions tailored to your
                  business!
                </p>
                <p>
                  🔍 Lost data? We excel
                 at Data Recovery and Computer Diagnostics!
                </p>

                <p>
                  🦠 Worried about viruses? We specialize in
                 Malware Removal and Software Installation!
                </p>
                <p>
                  🌐 Need a seamless Network Setup? We're the pros you need!
                </p>

                <p>
                  👩‍💻 Trust the Tampa Bay Computer Experts for all your tech
                  needs!
                </p>

                <p>
                  Contact us today at{" "}
                  <a href="tel:813-506-3416">813-506-3416</a> and experience
                  top-notch computer solutions!
                </p>
                
                <p className="p">
                  {" "}
                  We aim to resolve all issues on-site the same day and we offer
                  a 7 day guarantee on our services. If you're seeking a
                  Computer Engineer capable of resolving all your computer
                  issues, as opposed to a computer technician, enthusiast, or a
                  geek who can only address a subset of your problems, 
                  reach out to us at <a href="tel:813-506-3416">
                    813-506-3416
                  </a>{" "} 
                </p>
                <p><Appointment></Appointment></p>
                <p>{" "}<CallButton></CallButton></p>
              </section>

              <section ref={elementRef3} id="contact">
                <h2>Contact Us</h2>

                <p className="p">
                  If you need assistance with your computer, don't hesitate to
                  contact us. You can reach us by phone at{" "}
                  <a href="tel:813-506-3416">813-506-3416</a> or by{" "}
                  <a href="mailto:JohnRubio@TampaBayComputerRepairllc.com">
                    Email
                  </a>{" "}
                  . We often offer same-day service and can come to your address
                  to fix your computer problem. Let us take care of your
                  computer so you can get back to what's important.
                </p>

                <img
                  src={serviceRadius}
                  alt="service area"
                  width="300"
                  height="300"
                ></img>
              </section>
            </div>
          </div>
          <div className="keywords">
            {" "}
            <h2>
              🔧 Need Computer Help or Tech Support? We've got you covered! 🖥️
              Facing issues? Our Computer Repair and PC Troubleshooting experts
              are here to help! 💼 Looking for reliable IT Services and Computer
              Assistance? Look no further! 💻 Our dedicated team provides expert
              Computer Support and Tech Help! 🔍 For all your PC Help needs, we
              offer Computer Problem Solving and Computer Maintenance! handling
              all types of computer issues, from hardware to software,
              networking and security. Worried about viruses? we specialize in
              Virus Removal and Software Installation! Hardware troubles? Our
              Hardware Repair services have you covered! 🔍 Lost data? We excel
              at Data Recovery and Computer Diagnostics!🌐 Offering Remote Tech
              Support for your convenience! 💼 Looking for reliable IT Services
              and Computer Assistance? Look no further!Our Computer Repair and
              PC Troubleshooting experts are here to help! "Mobile Computer
              Repair: Fast and Reliable Service at Your Doorstep!" "Expert
              Mobile Tech Support for Your Computer Woes Call Now!" "On-Demand
              Computer Assistance Mobile Repair Services at Your Fingertips!"
              "Swift and Secure Mobile Tech Support for PCs Your Trusted
              Solution!" "Professional Computer Repair Comes to You! Mobile
              Services You Can Count On." "Quick Fixes, Anytime, Anywhere Mobile
              Computer Repair at Your Service!" "Your IT Team On the Go
              Mobile Computer Solutions for You!" "Fast and Efficient Mobile
              Support for Computer Issues We Come to You!" "Your Neighborhood
              Mobile Computer Repair Experts Quality Service Guaranteed!"
              "Convenient Mobile Solutions for Computer Problems Call for
              On-Site Assistance!"Tech Support Computer Repair PC
              Troubleshooting IT Services Computer Assistance Computer Support
              Tech Help PC Help Computer Problem Solving Computer Maintenance IT
              Solutions Virus Removal Software Installation ardware Repair
              Network Setup Data Recovery Computer Diagnostics Remote Tech
              Support Computer Experts
            </h2>
            <h2 className="servicearea">
              {" "}
              We provide on-site mobile services in the Greater Tampa Bay area,
              Brandon, Carrollwood, Cheval, Citrus Park, Clearwater, Dunedin,
              East Lake, East Lake-Orient Park, Egypt Lake-Leto, Gibsonton,
              Gulfport, Holiday, Hudson, Jasmine Estates, Keystone, Lake
              Magdalene, Land O' Lakes, Largo, Lutz, Mango, New Port Richey,
              Northdale, Oldsmar, Palm Harbor, Palm River-Clair Mel, Pinellas
              Park, Plant City, Riverview, Ruskin, Safety Harbor, Seminole,
              Spring Hill, St. Pete Beach, St. Petersburg, Sun City Center,
              Tampa, Tarpon Springs, Temple Terrace, Thonotosassa, Town 'n'
              Country, Trinity, University, Valrico, Wesley Chapel, Westchase
              and Zephyrhills.
            </h2>
          </div>

          <footer>
          <a id='COCBadgeURL' href='https://www.chamberofcommerce.com/business-directory/florida/lutz/computer-repair-service/2026167795-tampa-bay-computer-repair?source=memberwebsite' target='_tab'>
                <img alt="COC certified" id='COCBadgeIMG' src='https://coc.codes/images/badge/2026167795'></img></a>
            <p>© 2024 Tampa Bay Computer Repair LLC. All rights reserved.</p>
          </footer>
        </body>
        </div>
    </FluentProvider>
  );
};


export default App;
